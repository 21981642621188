.editableform .form-group {
    margin: 0;
}
.date .editableform .form-group {
    width: 312px;
}

.glyphicon {
    font-family: 'FontAwesome';
}
.glyphicon.glyphicon-ok:before {
    content: '\f00c';
}
.glyphicon.glyphicon-ok:before {
    content: '\f00c';
}
.glyphicon.glyphicon-remove:before {
    content: '\f00d';
}
.glyphicon.glyphicon-remove:before {
    content: '\f00d';
}

.glyphicon-arrow-left:before {
    content: '\f100';
}

.glyphicon-arrow-right:before {
    content: '\f101';
}

.item-wrapper a {
    /* make the link always show up */
    display: inline !important;
}

.item-wrapper {
    /* make absolutely positioned children constrained to this box*/
    position: relative;
}

.item-wrapper form {
    background: #fff;
    width: 310px;
    border: 1px solid #aaa;
    border-radius: 5px;
    display: inline-block;
    left: 50%;

    /* half the width */
    margin-left: -110px;
    padding: 7px;
    position: absolute;
    top: -55px;
    z-index: 101;
}

.item-wrapper form:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #aaa;
    position: absolute;
    bottom: -10px;
    left: 100px;
}

.item-wrapper form:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #fff;
    position: absolute;
    bottom: -9px;
    left: 101px;
}
.xeditable-muted > div > a {
    text-decoration: none;
    color: #428bca;
    border-bottom: dashed 1px #428bca;
    opacity: 0.6;
    font-style: italic;
}

@import '../../angle-theme/app/variables';

.panel-alert {
    border-left-width: 5px;
}

.panel-alert-success {
    @extend .panel-alert;
    border-left-color: $brand-success;
}

.panel-alert-notice {
    @extend .panel-alert;
    border-left-color: $brand-primary;
}

.panel-alert-error {
    @extend .panel-alert;
    border-left-color: $brand-danger;
}

.panel-alert-warning {
    @extend .panel-alert;
    border-left-color: $brand-warning;
}

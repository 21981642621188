@import '../../angle-theme/bootstrap/bootstrap/variables';

$input-border-color: #dde6e9;

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.animated {
    opacity: 0;
}

td.buttons a.btn,
th.buttons a.btn {
    width: 32px;
}

.yes-no-any > label:not(.active) {
    background-color: #fff;
    border-color: #eaeaea;
    color: #333;
}

.topnavbar .navbar-header .brand-logo {
    font-size: 24px;
    padding: 17px !important;
}

.navbar-header a.navbar-brand {
    color: #fff;
    text-decoration: none;
}

.panel-login {
    padding-bottom: 10px !important;
}

.login-icon {
    top: 10px;
}

.panel-heading {
    padding-bottom: 0;
    .pull-right {
        h3.panel-title {
            font-size: 14px;
            border-bottom: 0;
            padding-top: 11px;
        }
    }
}

.panel.panel-default {
    border-color: #e7eaec;
    border-top-width: 1px;
}

.panel.panel-default .panel-footer > label {
    padding-top: 7px;
}

h3.panel-title {
    border-bottom: 1px #eee solid;
    color: #656565;
    font-size: 18px;
    font-weight: normal;
    padding: 7px 0px 15px;
    & > i {
        margin-right: 12px;
    }
}

.sidebar > .nav > li > a,
.sidebar > .nav > li > .nav-item {
    padding: 4px 24px;
}

.sidebar-subnav > li > a,
.sidebar-subnav > li > .nav-item {
    padding-bottom: 5px;
    padding-top: 5px;
}

.sidebar > .nav > li:first-of-type > a {
    padding: 12px 24px 6px;
}

.sidebar-floating-last {
    margin-bottom: 20px !important;
}

.aside-collapsed .sidebar > .nav > li > a {
    padding: 17px;
}

.brand-logo-collapsed {
    padding-top: 15px !important;
}

/* Chosen JS override*/

body .chosen-container-multi .chosen-choices li.search-choice {
    margin: 2px 5px 2px 0;
}

body {
    .chosen-container-single .chosen-single,
    .chosen-container-active.chosen-with-drop .chosen-single,
    .chosen-container-multi .chosen-choices {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
}

// Add Edit pages
.back-nav {
    margin-top: -60px;
}

.table {
    .table {
        background-color: transparent;
        margin: -5px;
    }
    &-align-top > tbody > tr > td {
        vertical-align: top;
    }
}

.table > thead > tr > th {
    padding: 14px 8px;
    color: #666;
}

td,
th {
    &.buttons {
        text-align: center;
        white-space: nowrap;
        &.buttons-1 {
            width: 50px;
        }
        &.buttons-2 {
            width: 85px;
        }
        &.buttons-3 {
            width: 120px;
        }
        &.buttons-4 {
            width: 155px;
        }
        &.buttons-5 {
            width: 190px;
        }
        &.buttons-6 {
            width: 225px;
        }
        a.btn {
            width: 32px;
        }
    }
    &.buttons-right {
        text-align: right;
        a.btn {
            width: 32px;
        }
    }
    &.flag {
        text-align: center;
    }
    &.id {
        text-align: left;
    }
    &.number {
        text-align: right;
    }
    &.text {
        text-align: left;
    }
    &.label-cell {
        text-align: center;
    }
    &.select-cell {
        width: 20%;
        text-align: center;
        padding-left: 50px;
    }
    &.note {
        width: 20%;
    }
}

// Fixes an issue that stops chosen borders from turning blue upon focus (active) state.
.chosen-container-active {
    > a.chosen-single,
    ul.chosen-results,
    div.chosen-drop,
    ul.chosen-choices {
        border-color: #66afe9;
    }
}

.chosen-container-active.chosen-with-drop {
    > a.chosen-single {
        border-color: #66afe9;
    }
}

.btn-group {
    .btn.focus {
        border-color: #66afe9;
    }
}

.CodeMirror {
    border: 1px solid #dde6e9;
    border-radius: 4px;
}

body#tinymce {
    background-color: #fff;
    padding: 15px;
}

.wrapper .mce-tinymce.mce-container.mce-panel,
.modal .mce-tinymce.mce-container.mce-panel {
    border-radius: 4px;
}

.col-9-rmv .col-sm-9 {
    width: 100%;
    padding: 10px 0;
}

body.animated {
    opacity: 0;
}

textarea.stretch {
    resize: none;
}

td,
th {
    &.date {
        text-align: center;
        white-space: nowrap;
    }
    &.phone {
        white-space: nowrap;
    }
    &.description {
        min-width: 250px;
    }
}

span {
    &.date {
        white-space: nowrap;
    }
}

.no-wrap {
    white-space: nowrap;
}

h3.panel-title-paginate {
    color: #656565;
    font-size: 14px;
    font-weight: normal;
    margin: 10.5px 7px 10.5px 15px;
    white-space: nowrap;
    display:inline-block;
}

body {
    .chosen-container-multi {
        .chosen-choices {
            li.search-field {
                input[type='text'] {
                    font-size: 14px;
                    font-family: inherit;
                }
            }
        }
    }
}

.child-row {
    .row:first-child .col-sm-9 {
        margin-top: 5px;
    }
    .col-sm-9 {
        width: 100% !important;
        //padding: 0 10px 0 15px;
        padding: 0;
        margin: 3px 0;
        // margin: -5px 0;
    }
}

.x-scroll {
    width: 100%;
    overflow-x: auto;
}

.tour-step-background,
.tour-backdrop {
    position: fixed !important;
}

.panel-heading {
    h3.panel-title {
        i.fa {
            margin-right: 12px;
        }
    }
}

.active-card {
    color: #008000;
}

#modal-declined-transaction {
    margin-top: 300px;
}

.row-masonry > .col-masonry {
    margin-bottom: 45px;
}

.form-horizontal {
    .control-text {
        padding-top: 7px;
    }
    .bootstrap-filestyle {
        max-width: none;
    }
}

.progress.lg {
    height: inherit;
    & > .progress-bar {
        font-size: 18px;
    }
}

#notes {
    table tr {
        &:nth-child(2n) td {
            padding-bottom: 20px;
        }
        &:nth-child(2n + 1) {
            background-color: #e4eefc;
        }
    }
    &.panel {
        .panel {
            .panel-heading {
                padding-bottom: 5px;
                padding-top: 5px;
                opacity: 0.7;
            }
        }
    }
}

.tooltip-campaign {
    .tooltip-inner {
        max-width: none !important;
        white-space: nowrap !important;
    }
}

// Sidebar

.sidebar-subnav.nav-floating {
    padding-bottom: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
}

#account-nav-sidebar {
    h3.panel-title {
        padding: 3px 0 10px;
    }

    .sidebar-account-link {
        float: right;
        text-decoration: none;
        font-size: 18px;
        margin-top: 19px;
    }
    .sidebar-org-link {
        float: left;
        text-decoration: none;
        font-size: 30px;
        margin-top: 10px;
    }
    .p {
        margin: 10px;
        padding: 0 !important;
    }
    .border-bottom {
        padding-bottom: 15px !important;
        border-bottom: solid 1px #eee;
        margin-bottom: 15px;
    }
    .panel {
        .p {
            margin: 10px 0;
        }
    }
    .p#phone-numbers > small {
        display: block;
        margin-top: 5px;
        &:nth-child(2) {
            margin-top: 0;
        }
    }
}

p:last-child {
    margin-bottom: 0;
}

.panel.panel-success {
    border-color: $brand-success;
    a .panel-heading {
        background-color: $brand-success;
    }
    a:hover .panel-heading {
        background-color: darken($brand-success, 15%);
    }
}

.panel.panel-primary {
    border-color: $brand-primary;
    a .panel-heading {
        background-color: $brand-primary;
    }
    a:hover .panel-heading {
        background-color: darken($brand-primary, 15%);
    }
}

.panel.panel-danger {
    border-color: $brand-danger;
    a .panel-heading {
        background-color: $brand-danger;
    }
    a:hover .panel-heading {
        background-color: darken($brand-danger, 15%);
    }
}

.panel > a {
    text-decoration: none;
}

.offsidebar div.p p.link {
    margin: 0;
    a {
        display: block;
        margin: 0px -5px;
        padding: 5px;
        text-decoration: none;
        &:hover {
            background: #f5f5f5;
            text-decoration: none;
        }
    }
}

.product-title h3 {
    font-weight: normal;
    color: #fff;
    line-height: 55px;
    margin: 0;
    padding-left: 20px;
}

a:hover {
    cursor: pointer;
}

.divider {
    margin: 30px 0;
    border-bottom-style: solid;
    border-bottom-color: #ced5da;
    border-bottom-width: 1px;
}

.form-horizontal.label-left {
    .control-label {
        text-align: left;
    }
}

#multi-services-panel {
    .expand-btn {
        padding: 0.2em;
        background-color: #e4eaec;
        color: #ffffff;
        display: inline;
        border-radius: 0.25em;
        font-size: 75%;
        &:hover {
            background-color: #c6d3d7;
        }
        &.in {
            i.fa.fa-plus:before {
                content: '\f068';
            }
        }
    }
}

.popover {
    box-shadow: 4px 3px 16px #555;
}

tr.text-muted {
    .label,
    .btn,
    .btn:hover {
        background-color: #e4eaec;
    }
    .btn:hover {
        border: none;
    }
}

.Select {
    .Select-control,
    .Select-outer-menu {
        border: 1px solid $input-border-color !important;
    }
    .Select-arrow-zone {
        .Select-arrow {
            border-top-color: $input-border-color;
        }
        &:hover {
            .Select-arrow {
                border-top-color: darken($input-border-color, 10%) !important;
            }
        }
    }
}

.form-control {
    padding: 6px 10px;
}

.form-group.has-error {
    .chosen-container {
        .chosen-single,
        .chosen-drop {
            border-color: #f05050;
        }
    }
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.well-no-results {
    padding: 60px 120px;
    margin: 0px;
    background-color: #f5f5f5;
}
.well-no-results-sm {
    margin: 0px;
    background-color: #f5f5f5;
}
.form-group .switch {
    margin-top: 7px;
}

a.progress-bar:hover {
    color: #fff;
    text-decoration: none;
}

.phone-label,
.email-label {
    font-size: 14px;
    padding: 1px 5px;
}

td,
th {
    &.flag {
        text-align: center;
    }
}

.label-yes {
    background-color: #27c24c;
    color: #fff;
}

.label-no {
    background-color: #f05050;
    color: #fff;
}

.keepright {
    color: #6939af;
}

.priority {
    color: #27c24c;
}

.collections {
    color: #f05050;
}

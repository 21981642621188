#modal-delete-item {
    .btn {
        display: inline-block;
    }
    form {
        margin-bottom: 10px;
    }
}
#delete-modal-content-ok,
#modal-delete-item-no-delete {
    display: none;
}
#modal-delete-spinner {
    /*	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1050;
	*/
    .modal-body {
        height: 150px;
    }
    &.loaded .modal-body {
        height: auto !important;
    }

    .line-spin-fade-loader {
        position: relative;
        left: 50%;
        top: 45%;
    }
}

.iframe-show {
    width: 100%;
    height: 100%;
    border: none;
}

#root {
    height: 100%;
}

.section-no-match {
    margin-bottom: 0px !important;
    overflow-y: scroll;
    top: 0;
}

.div-no-match {
    height: 100%;
}

#account-nav-sidebar > nav {
    margin-bottom: 20px;
}

.custom-users-modal {
    width: 85%;
}

.deleting {
    transition: all 1.5s ease-out;
    opacity: 0;
    transform: translateY(2000%);
}
